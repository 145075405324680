@import '../../../../styles/base/variables';
@import '../../../../styles/base/mixins';

.tabsWrapper {
  background-color: $gb_white;

  ul {
    margin-bottom: 0;

    li {
      max-height: 2.75rem;

      > div {
        max-height: 2.75rem;
      }
    }

    @include screen-md {
      li {
        max-height: 2.5rem;

        > div {
          max-height: 2.5rem;
        }
      }
    }
  }
}
