@import 'styles/base/variables';
@import 'styles/base/mixins';

.introText {
  margin-bottom: 3.125rem;
  @include bullet-points-spacing();

  @include screen-md {
    margin-bottom: 4.375rem;
  }
}
