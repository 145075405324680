@import 'styles/base/variables';
@import 'styles/base/mixins';

.pressPortalDetailPage {
  @include bullet-points-spacing();

  &#{&} p {
    margin: initial;
    max-width: unset;
  }

  & .fullWidth {
    padding: 0;
  }

  & .imageContainer {
    > div {
      overflow: hidden;
    }

    & .heroImageC {
      width: 100%;
      height: 100%;
    }
  }

  .tilesHeadline h2 {
    font-weight: 700 !important;
    text-transform: uppercase !important;
  }

  & .socialIconsWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;

    > div:first-child {
      transform: translateX(-10px);
    }

    @include screen-sm {
      flex-direction: row;
      align-items: center;
      row-gap: unset;
      column-gap: 1rem;
      margin-bottom: 0.5rem;
      transform: translateX(-10px);
      > div:first-child {
        transform: none;
      }
    }

    @include screen-md {
      margin-top: 2rem;
      column-gap: 1rem;
    }

    & p {
      color: $gb_gdds_cl13;
      margin: 0;
    }

    hr {
      display: none;
      margin: 0;
      background-color: rgba(0, 0, 0, 0.16);

      @include screen-sm {
        display: initial;
        height: 1.5rem;
      }
    }
  }

  & h1 {
    margin: 0;
    margin-bottom: 1rem;
  }

  & .summary,
  & .noGddsSummary,
  & .copyText > p,
  & .noGddsCopyText > p {
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }

  & .summary {
    font-size: 1rem;
    font-weight: 700;
  }

  & .noGddsSummary,
  & .noGddsCopyText p {
    font-size: 15px !important;
    font-weight: 400;
  }

  & .copyText {
    > p {
      font-size: 1rem;
    }

    a:before {
      content: none;
    }

    figure {
      margin-bottom: 1.5rem;
    }
  }

  & .document {
    margin-bottom: 5rem;
  }

  & hr + hr {
    display: none;
  }
}
