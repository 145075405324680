@import 'styles/base/variables';
@import 'styles/base/mixins';

.checkoutIntro {
  margin-bottom: 5.625rem;
}

.checkoutIntroHeadline h1 {
  margin: 0 0 3.125rem;
}

.checkoutIntroText {
  margin-bottom: 1.875rem;

  span {
    font-size: 1rem;
  }

  @include screen-sm {
    margin-bottom: 3.75rem;
  }
}

.editButton:hover {
  color: #000;
  background-color: $gb_black_08;
}

.checkoutButtons {
  display: flex;
  flex-direction: column;

  @include screen-sm {
    flex-direction: row;
    justify-content: right;
  }

  button:first-of-type {
    margin-bottom: 0.9375rem;

    @include screen-sm {
      margin-bottom: 0;
      margin-right: 1.875rem;
    }

    i::before {
      font-size: 1.125rem;
    }
  }
}
