@import 'styles/base/variables';
@import 'styles/base/mixins';

.pressPortalAssetDetailPage {
  & .titleWrapper {
    h1 {
      margin: 0;
    }

    p {
      margin: 0 0 0.75rem 0;
      color: $gb_grey_650;
    }

    margin-top: 2rem;
    margin-bottom: 4rem;

    @include screen-sm {
      margin-top: 3rem;
    }

    @include screen-md {
      margin-bottom: 5rem;
    }
  }

  & .document {
    margin-bottom: 4rem;

    @include screen-sm {
      margin-bottom: 5rem;
    }

    @include screen-md {
      margin-bottom: 7rem;
    }

    & .headline {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin: 0 0 0.75rem 0;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        padding-bottom: 0;
      }
    }

    .documentBorder {
      border: 1px solid $gb_gdds_cl09;
    }

    &.documentFontSize p {
      font-size: 15px;
      line-height: 1.5rem;
    }
  }

  & .desc {
    padding-left: 0.5rem;
    padding-top: 1.5rem;

    @include screen-md {
      padding-top: 0;
    }

    button {
      margin-top: 1.5rem;
    }
  }

  & .wrapper {
    display: flex;
    flex-direction: column;

    @include screen-sm {
      flex-direction: row;

      &.landscape {
        flex-direction: column;
      }
    }

    @include screen-md {
      flex-direction: row;

      &.landscape {
        flex-direction: row;
      }
    }

    > div {
      flex: 1;
    }
  }

  & .imageContainer {
    > div {
      overflow: hidden;
    }

    & .heroImageC {
      width: 100%;
    }
  }

  & hr + hr {
    display: none;
  }
}
