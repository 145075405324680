@import 'styles/base/variables';
@import 'styles/base/mixins';

.videoChannelWrapper {
  &#{&} {
    margin-top: 2rem;
    margin-bottom: 6.5rem;
  }

  @include screen-md {
    &#{&} {
      margin-top: 3rem;
      margin-bottom: 7.5rem;
    }
  }

  & .titleRow {
    margin-bottom: 4rem;

    @include screen-md {
      margin-bottom: 5rem;
    }

    h1 {
      margin: 0 0 3rem;
    }
  }

  & .mainVideoRow {
    margin-bottom: 4rem;

    h3 {
      margin-top: 2.25rem;
      margin-bottom: 0.75rem;

      &.headlineKolo {
        font-weight: $font-weight-bold;
      }
    }

    & .copyText {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0;
    }

    @include screen-sm {
      margin-bottom: 5.75rem;

      h3 {
        margin-left: 0.75rem;
        margin-top: 0rem;
      }

      & .copyText {
        margin-left: 0.75rem;
        margin-top: 0rem;
      }
    }
  }

  & .dropdownRow {
    margin-bottom: 2rem;

    ul {
      z-index: 99;

      > li {
        padding-bottom: 0.625rem;
      }
    }
  }

  & .videocenterChannel {
    --column-gap: 1.5rem;

    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: var(--column-gap);
    row-gap: 2rem;

    @include screen-md {
      row-gap: 2.5rem;
    }

    > div {
      > div[role='img'] {
        margin-bottom: 1rem;
      }

      padding: 0;
      margin: 0;

      flex-basis: 100%;

      @include screen-sm {
        flex-basis: calc(calc(100% - calc(1 * var(--column-gap))) / 2);
      }

      @include screen-md {
        flex-basis: calc(calc(100% - calc(2 * var(--column-gap))) / 3);
      }
    }
  }

  & .loadmoreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;

    > button {
      > span:nth-child(2) {
        width: unset;
        align-items: center;
      }
    }
  }

  + div {
    z-index: 99;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: portrait) {
    + div {
      padding: 0;

      > div > div {
        width: 100vw;
        height: 100vh;
        max-width: unset;
        margin: 0;
      }

      div[type='custom'] {
        margin: 0 auto;
      }
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 1023px) and (orientation: landscape) {
    + div {
      padding: 0;

      > div > div {
        min-height: unset;
        height: 100vh;
        overflow: auto;
      }
    }
  }
}

.lightboxContainer {
  & .copyText {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  & .shareText {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 0;
  }

  div[type='custom'] {
    > div:nth-child(2) {
      display: none;
    }
  }
}
