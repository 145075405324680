@import 'styles/base/variables';
@import 'styles/base/mixins';

.noResultsWrapper {
  &.documentFontSize p {
    font-size: 15px !important;
  }

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  min-height: 15.25rem;

  @include screen-sm {
    margin: 2.5rem -1rem 0 -1rem;
    min-height: 16.25rem;
  }

  @include screen-md {
    margin: 3.5rem 0 0 0;
    min-height: 18rem;
  }

  ul,
  .text {
    margin: 0;
    padding: 0;
  }

  &#{&}#{&} .headline {
    margin: 0 0 2rem 0;
    font-size: 1.25rem;
    line-height: 1.75rem;

    @include screen-sm {
      font-size: 1.375rem;
    }

    @include screen-md {
      margin: 0 0 2.5rem 0;
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  &#{&}#{&} .xyHeadline {
    font-weight: $font-weight-bold;
    font-size: 1.5rem;
    line-height: 2rem;

    @include screen-sm {
      font-size: 1.625rem;
    }

    @include screen-md {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    @include screen-lg {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  & .subline {
    margin: 0 0 0.75rem 0;
  }
}
